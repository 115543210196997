import React from 'react';
import ContactForm from "./contactForm";
// import ContactInformation from './contactInformation';
import {Row } from 'antd';
import { useIntl } from "gatsby-plugin-intl"
const Contact = () => {
  const intl = useIntl();
  return (
    <div id={'contact'} className="seccionContact">
      <div className="beforeContact"></div>
      <h2 className="titleItem">{intl.formatMessage({ id: "contact.title"})}</h2>
      <h2 className="subtitleItem">{intl.formatMessage({ id: "contact.subtitle"})}</h2>
      <Row gutter={16}>
        {/*<ContactInformation />*/}
        <ContactForm language={'EN'}/>
      </Row>
    </div>
  );
};

export default Contact;