import React from "react"
import 'antd/dist/antd.css';
import Layout from "../components/layout"
import CardSnorkel from "../components/cardSnorkel";
import Contact from "../components/contact";
import ButtonsFrom from "../components/buttonsFrom";
import Gallery from "../components/gallery";
import SectionDesc from "../components/sectionDesc";
import ModalBook from "../components/modalBook";
import SEO from "../components/seo";
import { useIntl } from "gatsby-plugin-intl";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
// import {styleModalContext} from "./context";
// import Button from "@material-ui/core/Button";
import { graphql,useStaticQuery } from "gatsby";
// import Img from "gatsby-image";
import { Snorkel1,Snorkel2,Snorkel3,Snorkel4,Snorkel5 } from "../images"
const IndexPage = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
  query {
   logoSafe: file(relativePath: { eq: "SafeTravels.png" }) {
      childImageSharp {
        fluid(maxWidth: 184, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`)
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
  return(

    <div className="bgBlue">
      <Layout>
        <SEO title={intl.formatMessage({ id: "seo.title" })}   lang={intl.locale}/>
        {/*<div className="contSafe">*/}
          {/*<img className="logoSafeTravel" fixed={data.logoSafe.childImageSharp.fluid} alt=""/>*/}
        {/*</div>*/}

        <SectionDesc/>
        {/*<Row gutter={{ xs: 24, sm: 16, md: 24, lg: 32 }} className="img-desc">*/}
          {/*<Col className="gutter-row">*/}
            {/*<div >*/}
              {/*<img src={Snorkel1}/>*/}
            {/*</div>*/}
          {/*</Col>*/}
          {/*<Col className="gutter-row">*/}
            {/*<div >*/}
              {/*<img src={Snorkel2}/>*/}
            {/*</div>*/}
          {/*</Col>*/}
          {/*<Col className="gutter-row">*/}
            {/*<div >*/}
              {/*<img src={Snorkel3}/>*/}
            {/*</div>*/}
          {/*</Col>*/}
        {/*</Row>*/}
        <Swiper
          spaceBetween={25}
          slidesPerView={3}
          navigation={{ clickable: true }}
        >
          <SwiperSlide><img src={Snorkel1}/></SwiperSlide>
          <SwiperSlide><img src={Snorkel2}/></SwiperSlide>
          <SwiperSlide><img src={Snorkel3}/></SwiperSlide>
          <SwiperSlide><img src={Snorkel4}/></SwiperSlide>
          <SwiperSlide><img src={Snorkel5}/></SwiperSlide>
        </Swiper>
        {/*<div className="center"><Button className="btn-purchase">Purchase Tickets</Button></div>*/}
        <div>
          {/* Buttons From Component*/}
          <ButtonsFrom/>
          {/* Component Snorkel Card*/}
          <CardSnorkel/>
          {/* Gallery Component*/}
          <Gallery/>
          {/* Contact Component*/}
          <Contact/>
          <ModalBook/>
        </div>
      </Layout>
    </div>
  )
}

export default IndexPage
