import React from "react"
// import Button from "@material-ui/core/Button"
import { AndroidOutlined } from '@ant-design/icons';
import ButtonPrice from "./buttonPrice"
import { Tabs, Row, Col } from "antd"
import { Satisfaction, Idioma, Duration, Safe, Specs, Itinerary, Meeting, Sedetur } from "../images"
import { styleModalContext } from "./context"
import { useIntl } from "gatsby-plugin-intl"
// import { graphql, useStaticQuery } from "gatsby/index"
const CardSnorkel = () => {
  const { TabPane } = Tabs;
  function callback(key) {

  }
  /** @namespace props.descriptionItem */
  /** @namespace props.price */

  const intl = useIntl()

  return (
    (
        <styleModalContext.Consumer>
          {context => (
            <div>
            <div className="contItems">
              <div className="beforeContect"></div>
                <div className="infoContent">
                  <h2
                  className="titleItem">{context.from === "cozumel" ? intl.formatMessage({ id: "card_snorkel.title" }) : context.from==='pdc'?intl.formatMessage({ id: "card_snorkel.title_pdc" }):intl.formatMessage({ id: "card_snorkel.title_cun" })}</h2>
                  <h3 className="PriceItem">Price: ${context.from === "cozumel" ? intl.formatMessage({ id: "card_snorkel.price" }) : context.from==='pdc'?intl.formatMessage({ id: "card_snorkel.price_pdc" }):intl.formatMessage({ id: "card_snorkel.price_cun" })}</h3>
                  <h2
                    className="subtitleItem">{context.from === "cozumel" ? intl.formatMessage({ id: "card_snorkel.subtitle" }) : context.from==='pdc'?intl.formatMessage({ id: "card_snorkel.subtitle_pdc" }):intl.formatMessage({ id: "card_snorkel.subtitle_cun" })}us</h2>
                  {/*<p*/}
                    {/*className="descriptionItem">{context.from === "cozumel" ? intl.formatMessage({ id: "card_snorkel.paragraph" }) : intl.formatMessage({ id: "card_snorkel.paragraph_pdc" })}</p>*/}

                  <ul>
                    <li><span></span> {context.from === "cozumel" ? <span className="Vnone">&nbsp;</span> : context.from==='pdc'?<span className="Vnone">&nbsp;</span> : intl.formatMessage({ id: "card_snorkel.pickup_cun" })}</li>
                    <li><span></span> {context.from === "cozumel" ? <span className="Vnone">&nbsp;</span> : context.from==='pdc'?intl.formatMessage({ id: "card_snorkel.ferry_pdc" }) : intl.formatMessage({ id: "card_snorkel.ferry_cun" })}</li>
                    <li><span></span>All admissions fee</li>
                    <li><span></span>Full snorkeling gear, new snorkel tubes</li>
                    <li><span></span>Marine transportation</li>
                    <li><span></span>Water, sodas, onboard the catamaran (margaritas and rum punch after the snorkel activity)</li>
                    <li><span></span>Buffet at the beach club (mini hamburgers, fried chicken, French fries, ceviche, salad)</li>
                  </ul>
                  <div className="text-restriction">
                    *Restrictions Apply. All services are provided upon availability and in a first come - first serve basis.

                    <div>
                      <h4>Safety First Program</h4>
                      <div className="contProgram">
                        <img className="logoSafeTravel" src={Safe}/>
                        <img className="logoSedetur" src={Sedetur}/>
                      </div>
                    </div>
                  </div>
                </div>

                  <Row className="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={8}>
                      <div className="detailItem">
                        <img src={Satisfaction} alt="Satisfaction Guaranyee"/>
                        <span className="txtBold">{context.from === "cozumel" ? intl.formatMessage({ id: "card_snorkel.satisfaction" }) : intl.formatMessage({ id: "card_snorkel.satisfaction_pdc" })}</span>
                      </div>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <div className="detailItem">
                        <img src={Idioma} alt="Language"/>
                        <span className="txtBold"> {intl.formatMessage({ id: "card_snorkel.language" })} <span className="textRegular"> {intl.formatMessage({ id: "card_snorkel.language_options" })}</span></span>
                      </div>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <div className="detailItem">
                        <img src={Duration} alt="Duration"/>
                        <span className="txtBold">{intl.formatMessage({ id: "card_snorkel.duration_title" })} <span className="textRegular">{context.from === "cozumel" ? intl.formatMessage({ id: "card_snorkel.duration" }) : context.from === "pdc" ? intl.formatMessage({ id: "card_snorkel.duration_pdc" }) : intl.formatMessage({ id: "card_snorkel.duration_cun"})}</span></span>
                      </div>
                    </Col>
                  </Row>
            </div>
              <div className="beforeTabs"></div>
              <Tabs defaultActiveKey="1" centered onChange={callback}>
                <TabPane tabBarStyle={{border:'#ffffff'}} tab={<span><img src={Specs} className="icon-tab"/> {intl.formatMessage({ id: "tabs.tour_specifications_title" })} </span>} key="1">
                  <div className="contTab">
                    {/*<p>{context.from === "cozumel" ? intl.formatMessage({ id: "tabs.tour_specifications_czm" }) : intl.formatMessage({ id: "tabs.tour_specifications_pdc" })}</p>*/}
                    <p>{context.from === "cozumel" ? intl.formatMessage({ id: "tabs.tour_specifications_czm" }) : context.from==='pdc'?intl.formatMessage({ id: "tabs.tour_specifications_pdc" }):intl.formatMessage({ id: "tabs.tour_specifications_cun" })}</p>
                  </div>
                </TabPane>
                <TabPane tab={<span><img src={Itinerary} className="icon-tab"/> {intl.formatMessage({ id: "tabs.itinerary_title" })}</span>} key="2">
                  <div className="contTab">
                    <ul>
                      <li><span></span> {context.from === "cozumel" ? intl.formatMessage({ id: "tabs.itinerari1" }) : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari1_pdc" }):intl.formatMessage({ id: "tabs.itinerari1_cun" })}</li>
                      <li><span></span> {context.from === "cozumel" ? intl.formatMessage({ id: "tabs.itinerari2" }) : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari2_pdc" }):intl.formatMessage({ id: "tabs.itinerari2_cun" })}</li>
                      <li><span></span> {context.from === "cozumel" ? intl.formatMessage({ id: "tabs.itinerari3" }) : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari3_pdc" }):intl.formatMessage({ id: "tabs.itinerari3_cun" })}</li>
                      <li><span></span> {context.from === "cozumel" ? intl.formatMessage({ id: "tabs.itinerari4" }) : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari4_pdc" }):intl.formatMessage({ id: "tabs.itinerari4_cun" })}</li>
                      <li><span></span> {context.from === "cozumel" ? intl.formatMessage({ id: "tabs.itinerari5" }) : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari5_pdc" }):intl.formatMessage({ id: "tabs.itinerari5_cun" })}</li>
                      <li><span></span> {context.from === "cozumel" ? intl.formatMessage({ id: "tabs.itinerari6" }) : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari6_pdc" }):intl.formatMessage({ id: "tabs.itinerari6_cun" })}</li>
                      <li><span></span> {context.from === "cozumel" ? intl.formatMessage({ id: "tabs.itinerari7" }) : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari7_pdc" }):intl.formatMessage({ id: "tabs.itinerari7_cun" })}</li>
                      <li><span></span> {context.from === "cozumel" ? intl.formatMessage({ id: "tabs.itinerari8" }) : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari8_pdc" }):intl.formatMessage({ id: "tabs.itinerari8_cun" })}</li>
                      <li><span></span> {context.from === "cozumel" ? intl.formatMessage({ id: "tabs.itinerari9" }) : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari9_pdc" }):intl.formatMessage({ id: "tabs.itinerari9_cun" })}</li>
                      <li><span></span> {context.from === "cozumel" ? intl.formatMessage({ id: "tabs.itinerari10" }) : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari10_pdc" }):intl.formatMessage({ id: "tabs.itinerari10_cun" })}</li>
                      <li><span></span> {context.from === "cozumel" ? <span>&nbsp;</span> : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari11_pdc" }):intl.formatMessage({ id: "tabs.itinerari11_cun" })}</li>
                      <li><span></span> {context.from === "cozumel" ? <span>&nbsp;</span> : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari12_pdc" }):intl.formatMessage({ id: "tabs.itinerari12_cun" })}</li>
                      <li><span></span> {context.from === "cozumel" ? <span>&nbsp;</span> : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari13_pdc" }):intl.formatMessage({ id: "tabs.itinerari13_cun" })}</li>
                      <li><span></span> {context.from === "cozumel" ? <span>&nbsp;</span> : context.from==='pdc'?intl.formatMessage({ id: "tabs.itinerari14_pdc" }):intl.formatMessage({ id: "tabs.itinerari14_cun" })}</li>
                    </ul>
                  </div>
                </TabPane>
                <TabPane tab={<span><img src={Meeting} className="icon-tab"/>{intl.formatMessage({ id: "tabs.meeting_instructions_title" })}</span>} key="3">
                  <div className="contTab">
                    <p>{context.from === "cozumel" ? intl.formatMessage({ id: "tabs.meeting_instructions" }) : context.from==='pdc'?intl.formatMessage({ id: "tabs.meeting_instructions" }):intl.formatMessage({ id: "tabs.meeting_instructions" })}</p>
                    <p>{context.from === "cozumel" ? intl.formatMessage({ id: "tabs.meeting_instructions2" }) : context.from==='pdc'?intl.formatMessage({ id: "tabs.meeting_instructions2" }):intl.formatMessage({ id: "tabs.meeting_instructions2" })}</p>
                  </div>
                </TabPane>
              </Tabs>

              <div className="mt-26">
                <div className="center">
                  <ButtonPrice/>
                </div>
              </div>
            </div>
          )}

        </styleModalContext.Consumer>
    )

  )
}

export default CardSnorkel