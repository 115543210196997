import React,{useState} from 'react';
import {Row,Col,Button,Input } from 'antd';
import { useIntl } from "gatsby-plugin-intl";
import axios from 'axios';
const ContactForm = () => {
  const intl = useIntl();
  const [visibleMessage, isVisibleMesagge] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState({name:'',email:'',message:''});
  const [buttonText, setButtonText] = useState(intl.formatMessage({ id: "contact.submit_input"}));
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  let contactFormSubmit = (e) => {
    console.log(e);
    e.preventDefault();
    let messageSending=intl.locale==='en'? 'Sending': 'Enviando';
    // isSending(true)
    setButtonText(messageSending);
    const  formData=new FormData();
    formData.append("name",name);
    formData.append("email",email);
    formData.append("message",message);
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    axios.post(process.env.GATSBY_MAIL_URL, formData)
      .then( res => {
        if(res.status===200){
          isVisibleMesagge(true)
          setError({})
          // isSending(false)
          setName('')
          setEmail('');
          setMessage('');
          setButtonText(intl.formatMessage({ id: "contact.submit_input"}))
          setTimeout(() => {
            isVisibleMesagge(false)
          }, 2000);
        }
      })
      .catch( (error) => {
        console.log(error.response)
        if(error.response===undefined){

        }else {
          if(error.response.status===400){
            setButtonText(intl.formatMessage({ id: "contact.submit_input"}));
            // isSending(false);
            setError(error.response.data);
          }
        }


        // isSending(false)
      })
  }
  let inputName =value => {
    let valueInput=value.currentTarget.value;
    setName(valueInput);
  };
  let inputEmail =value => {
    let valueInput=value.currentTarget.value;
    setEmail(valueInput);
  };
  let inputMessage =value => {
    let valueInput=value.currentTarget.value;
    setMessage(valueInput);
  };

  return (
    <Col className="colGallery gutter-row colForm" span={12} offset={6}>
      <form layout="vertical"  className="formContact" id={'formContact'}>
        {
          visibleMessage===true ? <div>
            <div className="textEnviado"><div className="contMensaje">{intl.formatMessage({ id: "message_email"})}</div></div>
          </div> : <div><Row>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} className="colMessage">
            <span  className={'contactSpan'}>{intl.formatMessage({ id: "contact.name_input"})}</span>
            {error.name===undefined?'':error.name.length>0?<span className="textError">{error.name}</span>:''}
            <Input onChange={inputName} value={name} name={'name'} placeholder={''}/>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} className="colMessage">
            <span  className={'contactSpan'}>{intl.formatMessage({ id: "contact.email_input"})}</span>
            {error.email===undefined?'':error.email.length>0?<span className="textError">{error.email}</span>:''}
            <Input onChange={inputEmail} value={email} name={'email'} placeholder={''}/>
            </Col>
            </Row>
            <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="colMessage">
            <span  className={'contactSpan'}>{intl.formatMessage({ id: "contact.doubt_input"})}</span>
            {error.name===undefined?'':error.message.length>0?<span className="textError">{error.message}</span>:''}
            <Input  onChange={inputMessage} value={message} name={'message'} placeholder={''}/>
            </Col>
          </Row>
            <div className="center">
              <Button onClick={contactFormSubmit}   form="formContact"   htmlType="submit" className="btn-purchase">{buttonText}</Button>
            </div>
          </div>
        }
      </form>
    </Col>
  );
};

export default ContactForm;