import React,{useState,useEffect} from 'react';
import {InputNumber,DatePicker,Button } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { useIntl } from "gatsby-plugin-intl";
import { CloseCircleFilled } from '@ant-design/icons';
import {styleModalContext} from "./context";
const Modal = () => {
  const intl = useIntl();
  const [paxAdult, setPaxAdult] = useState(1);
  const [paxChild, setPaxChild] = useState(0);
  const [datesDisabled, setDisableDates] = useState('');
  useEffect(() => {
    // get data from GitHub api

    axios.get(process.env.GATSBY_DATES_URL)
      .then( res => {
        if(res.status===200){
          setDisableDates(res.data)
        }
      })
      .catch( (error) => {
        if(error.response===undefined){

        }else {
          if(error.response.status===400){

          }
        }
      })
  }, [])
  let changeInput = name => value => {
    if(name==='adult'){
      setPaxAdult(value)
    } else{
      setPaxChild(value)
    }
  };
  let today=()=>{
    return moment().format("MMM-DD-YYYY");
  }

  let disableDates=(current)=>{
    let customDate = moment().format("MMM-DD-YYYY");
    return (
      ( moment(current).day() === 0) ||( current && current < moment(customDate, "MMM-DD-YYYY") ) ||
      datesDisabled.find(date => date === moment(current).format("MMM-DD-YYYY"))
    );
  }

    return (

      <styleModalContext.Consumer>
        {context =>(
          <div className={context.open ? "contModal" : "closeModal"}>
            <div className="modalBook">
              <div className="ContainerModal">
                <form className="formContact" action={'https://www.playamiashoppingcart.com/includes/mete_producto.php'}  id="formExcursion" method="post">
                  <div className="contClose">
                    <CloseCircleFilled onClick={() => context.openModal()} className="closeIcon"/>
                  </div>
                  <h2 className="titleItem">{intl.formatMessage({ id: "modal_catamaran_beach_grill.titleP" })}</h2>
                  <h2 className="subtitleItem">{intl.formatMessage({ id: "modal_catamaran_beach_grill.title" })}</h2>

                  <ul className="flex">
                    <select style={{display:'none'}} name="paquete" id="paquete">
                      <option  value={context.from === "cozumel" ? intl.formatMessage({ id: "card_snorkel.id" }) : context.from==='pdc'?intl.formatMessage({ id: "card_snorkel.id_pdc" }):intl.formatMessage({ id: "card_snorkel.id_cun" })}>Cozumel Snorkel Tour: Coral Reef, El Cielo & Playa Mia</option>
                    </select>
                    <li style={{display:context.open ? "" : "none"}}>
                      <span className="labelModal">Tour: </span><div className="ant-input tituloModalItem">{intl.formatMessage({ id: "modal_catamaran_beach_grill.title_tour" })}</div>
                    </li>
                    <li style={{display:context.open ? "" : "none"}}>
                      <span className="labelModal">{intl.formatMessage({ id: "modal_catamaran_beach_grill.date" })} </span>
                        <DatePicker
                          name={"fecha"}
                          id={"fecha"}
                          defaultValue={moment(today())}
                          format={'MMM-DD-YYYY'}
                          // disabledDate={disableDates}
                          placeholder={intl.formatMessage({ id: "modal_catamaran_beach_grill.date" })}
                          className="tituloModalItem ant-input"
                        />
                    </li>

                    <li style={{display:context.open ? "" : "none"}} >
                      <span className="labelModal">{intl.formatMessage({ id: "modal_catamaran_beach_grill.adult" })} </span>
                          <InputNumber
                            name={"adultos"}
                            id={"adultos"}
                            onChange={changeInput('adult')}
                            defaultValue={1}
                            min={1}
                            className="tituloModalItem ant-input"/>
                    </li>

                    <li style={{display:context.open ? "" : "none"}} className="mr-0">
                      <span className="labelModal">{intl.formatMessage({ id: "modal_catamaran_beach_grill.children" })} </span>
                          <InputNumber
                            name={"ninos"}
                            id={"ninos"}
                            defaultValue={0}
                            disabled={paxAdult<1}
                            onChange={changeInput('child')}
                            min={0}
                            className="tituloModalItem ant-input"/>
                    </li>
                  </ul>
                  <div className="contTotal">
                    <span className="textTotal">{intl.formatMessage({ id: "modal_catamaran_beach_grill.total_price" })} </span>
                    {/*aqui solo le pones la oeracion de paxadult por prece adulto mas price infante por pax infante*/}
                    <span className="total"> ${(paxAdult+paxChild)*context.priceSnorkel} us</span>
                  </div>
                  <div style={{display:context.open ? "" : "none"}} className="cont-btn-modal">
                    <Button form="formExcursion" key="submit"  htmlType="submit" className="btn-purchase MuiButtonBase-root">
                      {intl.formatMessage({ id: "card_snorkel.book_now" })}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </styleModalContext.Consumer>

    )
  }

export default Modal;