import React, { useState } from "react"
import Carousel, { Modal, ModalGateway } from "react-images"
import { Row, Col } from "antd"
import { CZM1, CZM2, CZM3, CZM4, CZM5, CZM6, CZM7, CZM8, CZM9, CZM10, CZM11, CZM12 } from "../images"
import { useIntl } from "gatsby-plugin-intl";
import { graphql,useStaticQuery } from "gatsby";
import Img from "gatsby-image";
const Gallery = () => {
  const [modalIsOpen, toggleModal] = useState(false)
  const intl = useIntl()
  const [current, numeracion] = useState(0)
  const currentImage=(id)=>{
    numeracion(id)
    toggleModal(true)
  }
  const data = useStaticQuery(graphql`
  query {
   CZM1: file(relativePath: { eq: "gallery/czm/czm-snorkel-1.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
   CZM2: file(relativePath: { eq: "gallery/czm/czm-snorkel-2.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
   CZM3: file(relativePath: { eq: "gallery/czm/czm-snorkel-3.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }, 
   CZM4: file(relativePath: { eq: "gallery/czm/czm-snorkel-4.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },  
   CZM5: file(relativePath: { eq: "gallery/czm/czm-snorkel-5.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },  
   CZM6: file(relativePath: { eq: "gallery/czm/czm-snorkel-6.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },   
   CZM7: file(relativePath: { eq: "gallery/czm/czm-snorkel-7.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },   
   CZM8: file(relativePath: { eq: "gallery/czm/czm-snorkel-8.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },  
    CZM9: file(relativePath: { eq: "gallery/czm/czm-snorkel-9.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    CZM10: file(relativePath: { eq: "gallery/czm/czm-snorkel-10.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    CZM11: file(relativePath: { eq: "gallery/czm/czm-snorkel-11.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    CZM12: file(relativePath: { eq: "gallery/czm/czm-snorkel-12.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
  }
`)
  const images = [{ source: CZM1 }, { source: CZM2 }, { source: CZM3 }, { source: CZM4 }
    , { source: CZM5 }, { source: CZM6 }, { source: CZM7 }, { source: CZM8 }, { source: CZM9 }, { source: CZM10 }, { source: CZM11 }, { source: CZM12 }]
  return (
    <div id={"gallery"} className="seccionGallery center">
      <h2 className="titleItem">{intl.formatMessage({ id: "tittle_gallery" })}</h2>
      <h2 className="subtitleItem">{intl.formatMessage({ id: "subtittle_gallery" })}</h2>
      <Row gutter={16} className="row-gallery">
        <Col className="contGallery" xs={24} sm={6} md={6} lg={6} xl={6}>
          {/*<Img  onClick={() => currentImage(0)} fluid={data.CZM1.childImageSharp.fluid} />*/}
          <img onClick={() => currentImage(0)} src={CZM1}/>
        </Col>
        <Col className="contGallery" xs={24} sm={6} md={6} lg={6} xl={6}>
          <img onClick={() => currentImage(1)} src={CZM2}/>
        </Col>
        <Col className="contGallery" xs={24} sm={6} md={6} lg={6} xl={6}>
          <img onClick={() => currentImage(2)} src={CZM3}/></Col>
        <Col className="contGallery" xs={24} sm={6} md={6} lg={6} xl={6}>
          <img onClick={() => currentImage(3)} src={CZM4}/></Col>
      </Row>
      <Row gutter={16} className="row-gallery">
        <Col className="contGallery" xs={24} sm={6} md={6} lg={6} xl={6}>
          <img onClick={() => currentImage(4)} src={CZM5}/></Col>
        <Col className="contGallery" xs={24} sm={6} md={6} lg={6} xl={6}>
          <img onClick={() => currentImage(5)} src={CZM6}/></Col>
        <Col className="contGallery" xs={24} sm={6} md={6} lg={6} xl={6}>
          <img onClick={() => currentImage(6)} src={CZM7}/></Col>
        <Col className="contGallery" xs={24} sm={6} md={6} lg={6} xl={6}>
          <img onClick={() => currentImage(7)} src={CZM8}/></Col>
      </Row>
      <Row gutter={16} className="row-gallery">
        <Col className="contGallery" xs={24} sm={6} md={6} lg={6} xl={6}>
          <img onClick={() => currentImage(8)} src={CZM9}/></Col>
        <Col className="contGallery" xs={24} sm={6} md={6} lg={6} xl={6}>
          <img onClick={() => currentImage(9)} src={CZM10}/></Col>
        <Col className="contGallery" xs={24} sm={6} md={6} lg={6} xl={6}>
          <img onClick={() => currentImage(10)} src={CZM11}/></Col>
        <Col className="contGallery" xs={24} sm={6} md={6} lg={6} xl={6}>
          <img onClick={() => currentImage(11)} src={CZM12}/></Col>
      </Row>
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={() => toggleModal(false)}>
            <Carousel currentIndex={current} views={images}/>
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}
export default Gallery