import React from 'react';
import ButtonFrom from "../components/button"
import { useIntl } from "gatsby-plugin-intl"
import {styleModalContext} from "./context";
const ButtonsFrom = () => {
  const intl = useIntl();
  //aqui le pases la propiedad de precio de infantes
  return (
    <styleModalContext.Consumer>
      {context =>(
        <div className="center">
        <div className="titulo-departure"><span>Departure from</span></div>
        <div className="contBtn center">

          <ButtonFrom from={'cozumel'} price={75} title={intl.formatMessage({ id: "button_from.cozumel" })} styledComponent={context.from==='cozumel'?'btnItem btn-active':'btnItem'}/>
          <ButtonFrom from={'pdc'} price={99} title={intl.formatMessage({ id: "button_from.pdc" })} styledComponent={context.from==='pdc'?'btnItem btn-active':'btnItem'}/>
          <ButtonFrom from={'cun'} price={115} title={intl.formatMessage({ id: "button_from.cun" })} styledComponent={context.from==='cun'?'btnItem btn-active':'btnItem'}/>
        </div></div>
      )}
    </styleModalContext.Consumer>


  );
};

export default ButtonsFrom;