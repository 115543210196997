import React from 'react';
import Button from "@material-ui/core/Button";
import { useIntl } from "gatsby-plugin-intl";
import {styleModalContext} from "./context";
const ButtonPrice = () => {
  /** @namespace props.styledComponent */
  const intl = useIntl();
  return (
    <styleModalContext.Consumer>
      {context =>(
        <Button className="btn-purchase" onClick={() => context.openModal()}>

      {intl.formatMessage({ id: "card_snorkel.book_now"})} {/*<span className="priceItem">$*/}
          {/*{context.from === "cozumel" ? intl.formatMessage({ id: "card_snorkel.price" }) : context.from==='pdc'?intl.formatMessage({ id: "card_snorkel.price_pdc" }):intl.formatMessage({ id: "card_snorkel.price_cun" })} us</span>*/}
        </Button>
      )}
    </styleModalContext.Consumer>

  );
};

export default ButtonPrice;