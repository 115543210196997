import React from 'react';
import Button from "@material-ui/core/Button"
import {styleModalContext} from "./context";
const ButtonFrom = (props) => {
  //aqui le pasas la funcion de cambiar el precio de infante y dentro de ellas props.variabledeinfante
  return (
    <styleModalContext.Consumer>
      {context =>(
        <Button onClick={() => {context.isFrom(props.from); context.setPrice(props.price);}} className={`${props.styledComponent}`}>
          {props.title}
        </Button>
      )}
    </styleModalContext.Consumer>

  );
};

export default ButtonFrom;