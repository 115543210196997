import React from 'react';
import { useIntl } from "gatsby-plugin-intl"
const SectionDesc = () => {
  const intl = useIntl();
  return (
    <div className="seccionDesc">
      <h2 className="TitleUltra">{intl.formatMessage({ id: "title" })}</h2>
      <p className="P-Regular">{intl.formatMessage({ id: "paragraph_principal" })}</p>
      <hr className="hr"/>
    </div>
  );
};

export default SectionDesc;